export const nftConfigJSON = [
    {
        name: "Cylindrio", 
        type: "Baby", 
        location: "Baby/1", 
        length: 10
    }, 
    {
        name: "Sphero", 
        type: "Baby", 
        location: "Baby/1", 
        length: 10
    }, 
    {
        name: "Cubio", 
        type: "Baby", 
        location: "Baby/1", 
        length: 10
    }, 
    {
        name: "Caterpanic", 
        type: "Baby", 
        location: "Baby/12", 
        length: 10
    }, 
    {
        name: "Butterpump", 
        type: "Adult", 
        location: "Adult/24", 
        length: 8
    }, 
    {
        name: "Axolittle", 
        type: "Adult", 
        location: "Adult/20", 
        length: 12
    }, 
    {
        name: "Axolarge", 
        type: "Adult", 
        location: "Adult/29", 
        length: 10
    }, 
    {
        name: "Jeeto", 
        type: "Baby", 
        location: "Baby/7", 
        length: 18
    }, 
    {
        name: "Decentralshark", 
        type: "Adult", 
        location: "Adult/15", 
        length: 14
    }, 
    {
        name: "Lurkermon", 
        type: "Baby", 
        location: "Baby/3", 
        length: 23
    }, 
    {
        name: "Shodowgochi", 
        type: "Adult", 
        location: "Adult/3", 
        length: 23
    }, 
    {
        name: "Faux", 
        type: "Adult", 
        location: "Adult/13", 
        length: 14
    }, 
    {
        name: "Dexscrenno", 
        type: "Adult", 
        location: "Adult/6", 
        length: 14
    }, 
    {
        name: "Sniper Wolf", 
        type: "Adult", 
        location: "Adult/17", 
        length: 14
    }, 
    {
        name: "Stonermon", 
        type: "Baby", 
        location: "Baby/10", 
        length: 10
    }, 
    {
        name: "Bullishmon", 
        type: "Adult", 
        location: "Adult/1", 
        length: 13
    }, 
    {
        name: "Shortlax", 
        type: "Adult", 
        location: "Adult/9", 
        length: 14
    }, 
    {
        name: "Budgochi", 
        type: "Adult", 
        location: "Adult/22", 
        length: 10
    }, 
    {
        name: "Phantomon", 
        type: "Adult", 
        location: "Adult/14", 
        length: 14
    }, 
    {
        name: "Blockonite", 
        type: "Adult", 
        location: "Adult/12", 
        length: 14
    }, 
    {
        name: "Liquidotto", 
        type: "Adult", 
        location: "Adult/23", 
        length: 10
    }, 
    {
        name: "Hodlmon", 
        type: "Baby", 
        location: "Baby/6", 
        length: 12
    }, 
    {
        name: "Buyhi", 
        type: "Baby", 
        location: "Baby/5", 
        length: 16
    }, 
    {
        name: "Liquie", 
        type: "Baby", 
        location: "Baby/5", 
        length: 16
    }, 
]