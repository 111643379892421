export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const USER_LOADED = "USER_LOADED";
export const NFT_LOADED = "NFT_LOADED";
export const NFTDATA_LOADED = "NFTDATA_LOADED";
export const CHARACTER_LOADED = "CHARACTER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const WITHDRAW_SUCESS = "WITHDRAW_SUCESS";